import { graphql } from "gatsby";
import React from "react";
import { Layout } from "../components/Layout";
import { Section } from "../components/Section";
import { MiniHeading, SEO, SubPagesGrid } from "../components";
import { Pagination } from "../components/Pagination";
import getAuthorLink from "../utils/getAuthorLink";

const AuthorTemplate = ({ data, pageContext }) => {
  const { pageNumber, totalPages } = pageContext;
  const { datoCmsPostAuthor, datoCmsAuthorSetting, allDatoCmsBlogPost } = data;
  const posts = allDatoCmsBlogPost.nodes;
  const footer = datoCmsAuthorSetting.footerSection;

  return (
    <Layout>
      <SEO
        title={`${datoCmsPostAuthor.firstName} ${datoCmsPostAuthor.lastName}, Author at Rubric`}
        description={``}
        image={datoCmsAuthorSetting.heroBackground.url}
        url={pageContext.url}
        type="author"
        breadcrumbTrails={[
          [
            { name: "Resources", item: "https://rubric.com/en-us/blog/" },
            {
              name: `${datoCmsPostAuthor.firstName} ${datoCmsPostAuthor.lastName}`,
            },
          ],
        ]}
      />
      <Section
        funkySwooshPosition="Bottom"
        funkySwooshStyle="Subtle"
        backgroundImage={datoCmsAuthorSetting.heroBackground.gatsbyImageData}
        backgroundOverlay="Red"
        textAlign="center"
      >
        <MiniHeading title="Author" />
        <h1>
          {datoCmsPostAuthor.firstName} {datoCmsPostAuthor.lastName}
        </h1>
      </Section>
      <Section>
        <SubPagesGrid
          pages={posts.map((post) => ({
            id: post.id,
            title: post.title,
            description: post.seo.description,
            image: post.seo.image?.gatsbyImageData,
            slug: post.slug,
            subtitle: post.category.title.toUpperCase(),
          }))}
        />
        <Pagination
          totalPages={totalPages}
          activePage={pageNumber}
          pageButtonDestination={(i) =>
            `/${getAuthorLink(datoCmsPostAuthor.firstName)}${
              i !== 0 ? `/page/${i + 1}` : ""
            }`
          }
        />
      </Section>
      {!!footer && (
        <Section
          backgroundImage={footer.backgroundImage?.gatsbyImageData}
          backgroundOverlay={footer.backgroundOverlay}
          content={footer.content}
          contentOverflow={footer.contentOverlap}
          funkySwooshPosition={footer.funkySwooshPosition}
          funkySwooshStyle={footer.funkySwooshStyle}
          bottomSpacing={footer.bottomSpacing}
          textAlign={footer.textAlign}
        />
      )}
    </Layout>
  );
};

export const query = graphql`
  query AuthorPageQuery($id: String!, $startFrom: Int!) {
    allDatoCmsBlogPost(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { authors: { elemMatch: { originalId: { eq: $id } } } }
      limit: 9
      skip: $startFrom
    ) {
      nodes {
        id: originalId
        slug
        title
        category {
          title
        }
        seo {
          description
          title
          image {
            gatsbyImageData
          }
        }
      }
    }
    datoCmsPostAuthor(originalId: { eq: $id }) {
      firstName
      lastName
    }
    datoCmsAuthorSetting {
      heroBackground {
        url
        gatsbyImageData
      }
      footerSection {
        ...Link_Section
      }
    }
  }
`;

export default AuthorTemplate;
